<template>
  <Sect id="ProjectAbout">
    <Wrap class="lg">
      <Row class="projects">
        <Column
          v-for="project in projects"
          :key="project.id"
          :lg="projects.length === 1 ? 12 : 6">
          <ProjectHome :project="project" />
        </Column>
      </Row>

      <template v-if="isDesktop">
        <Blob class="left" name="project-blob-left" />
        <Blob class="right" name="project-blob-right" />
      </template>
      <slot />
    </Wrap>
  </Sect>
</template>

<script>
import ProjectHome from './ProjectHome'
import { getters } from '../../utils'

export default {
  components: {
    ProjectHome,
  },
  computed: {
    projects() {
      return this.query('project', {
        isListed: true,
        $sort: { order: 1 },
      })
    },
    ...getters('isDesktop'),
  },
}
</script>

<style lang="scss">
#ProjectAbout {
  background: linear-gradient(#5e36d4, #5054da, #3f79e5);
  color: $white;
  margin: 0 0 6rem;
  padding: 3rem 0 1rem;
  position: relative;

  &::before {
    background: url('../../../assets/landing/sweep-project.png') no-repeat;
    background-size: cover;
    content: ' ';
    height: 185px;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: -184px;
    width: 100%;
    @include xxl {
      background-size: 100% 185px;
    }
  }

  &::after {
    background: url('../../../assets/landing/sweep-why.png') no-repeat;
    background-size: cover;
    bottom: -184px;
    content: ' ';
    height: 185px;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
    @include xxl {
      background-size: 100% 185px;
    }
  }

  h2 {
    color: inherit;
    font-size: $h3;
    @include md {
      font-size: $h2;
    }
  }

  .why {
    margin-top: 5rem;
    @include xl {
      margin-top: 8rem;
    }

    h2 {
      @include lg {
        margin-bottom: 4rem;
      }
    }

    .IconColumns-arrow {
      color: $white;
    }
  }

  .Blob {
    z-index: 1;

    &.left {
      display: none;
      @include xxl {
        bottom: 20rem;
        display: block;
        left: 0;
        width: 20vw;
      }
    }

    &.right {
      display: none;
      @include xxl {
        bottom: 10rem;
        display: block;
        right: -4rem;
        width: 20vw;
      }
    }
  }
}

html.webp {
  #ProjectAbout {
    &::after {
      background-image: url('../../../assets/landing/sweep-why.webp');
    }

    &::before {
      background-image: url('../../../assets/landing/sweep-project.webp');
    }

    .Row.projects {
      justify-content: center;
    }
  }
}
</style>
