<template>
  <Footer class="FooterLanding">
    <Wrap class="lg">
      <HowToOrder classes="inv single-line" />
    </Wrap>
    <Wrap class="md">
      <slot name="form">
        <FormSignup id="signup" />
      </slot>
    </Wrap>
  </Footer>
</template>

<script>
import Footer from '../Footer'
// import FormUser from '../forms/FormUser'
import FormSignup from './../forms/FormSignup'
import HowToOrder from './HowToOrder'
import { getters } from './../../utils'

export default {
  components: { Footer, HowToOrder, FormSignup },
  data() {
    return {
      user: {},
      isSigningUp: false,
    }
  },
  computed: getters('userFactory'),

  beforeMount() {
    this.user = this.userFactory({
      passwordMatch: '',
      code: null,
    })
  },
}
</script>

<style lang="scss">
.Footer.FooterLanding {
  color: $white;

  .IconColumns-arrow {
    color: inherit;
  }

  h2 {
    color: inherit;
    font-size: $h3;
    margin-bottom: 2rem;
    @include lg {
      font-size: $h2;
      margin-bottom: 3rem;
    }
  }

  .Field .checkbox {
    color: inherit;
  }

  .FormSignup {
    .FormFloat {
      background-color: transparent;

      .link {
        color: inherit;

        &:hover {
          color: $main;
        }
      }

      a, h1, span {
        color: inherit;
      }
    }

    .btn-signup {
      background: $red;
    }

    .SocialLogin {
      a {
        background: $white;
        color: $blue;

        &:hover {
          background: $grey-lightest;
        }
      }

    }
  }
}
</style>
