<template>
  <div id="Index">
    <Hero
      :formats="['webp', 'png']">
      <!-- text="Aplinkai draugiškas būdas sutaupyti, tapus nuotolinės saulės elektrinės savininku" -->
      <div slot="title">
        Prisijunkite<br v-if="!isDesktop"> prie<br v-if="isDesktop"> saulės bendruomenės
      </div>
      <div slot="customSubtitle">
        <h4>
          Naujas saulės parkas „Rudamina“ jau priima rezervacijas!<br>
          Numatoma, jog parkas elektros energiją pradės generuoti kitų metų spalio mėnesį!
        </h4>
      </div>
      <div slot="actions">
        <Route class="Btn ib danger" to="Signup">
          Rezervuok
          <Icon name="right" />
        </Route>
        <Route class="Btn ib" :to="{ name: 'Index', hash: '#video' }">
          Sužinok daugiau
          <Icon name="right" />
        </Route>
      </div>
    </hero>

    <CardFlip />

    <!-- <div id="video">
      <Wrap class="xl">
        <Feature
          image="../assets/landing/video.png"
          :isLeft="!isDesktop"
          title="Nuo šiol iš saulės pagamintą elektros energiją galės naudoti kiekvienas Lietuvos gyventojas"
          video="https://www.youtube.com/embed/by3-0VcAgFA">
          <p>
            Nuo 2019 metų spalio 1 dienos visi Lietuvos gyventojai, net ir tie, kurie gyvena daugiabučiuose, galės tapti elektros energiją gaminančiais vartotojais ir savo kasdienėje buityje naudotis saulės elektros energija.
          </p>
          <p>
            Įsigaliojus šiai naujai sistemai, gyventojams sudaroma galimybė elektros energiją iš atsinaujinančių išteklių gaminti ir vartoti geografiškai skirtingose vietose.
          </p>
          <p>
            Sutaupyti ir prisidėti prie klimato kaitos mažinimo dar niekada nebuvo paprasčiau.
          </p>
        </Feature>
      </Wrap>
    </div> -->

    <!-- <div id="video">
      <Wrap class="xl">
        <Feature
          image="../assets/landing/video.png"
          :isLeft="!isDesktop"
          title="Rezervuokite elektrinės dalį saulės parke"
          video="https://www.youtube.com/embed/by3-0VcAgFA">
          <p>
            Saulės elektrinę iš saulės parko įsigyti yra ypač paprasta – jums nereikia rūpintis nei saulės elektrinės įrengimu, nei jos priežiūra. „Saulės bendruomenės“ komanda nuolat stebi elektrinės veikimą ir taip užtikrina, kad saulės elektrinė veiktų pilnu galimu pajėgumu ištisus metus. Gaminti ir vartoti saulės energiją iš saulės parkų gali visi LR gyventojai. Taigi butų savininkai, privačių namų savininkai ir bendrijos gali lengvai pradėti naudoti saulės energiją ir taip sumažinti savo elektros išlaidas net iki 70%.
          </p>
        </Feature>
      </Wrap>
    </div> -->
    <SectionNews />
    <SectionProjects />

    <ProjectAbout>
      <div class="why">
        <h2>Saulės bendruomenė -<br v-if="!isDesktop"> kodėl verta?</h2>
        <div>
          <Wrap class="lg">
            <IconColumns classes="inv" :data="why" />
          </Wrap>
        </div>
      </div>
    </ProjectAbout>

    <!--
    <Calculator
      :project="mainProject"
      :value.sync="amount" />
    -->

    <HowItWorks
      id="kaip-veikia"
      v-observe-visibility="{ callback: isVisible => trackView(isVisible), once: true }" />

    <FooterLanding />
  </div>
</template>

<script>
// import Calculator from './components/Calculator'
import CardFlip from './components/CardFlip'
import FooterLanding from './components/landing/FooterLanding'
import Hero from './components/landing/Hero'
import HowItWorks from './components/landing/HowItWorks'
import IconColumns from './components/landing/IconColumns'
import Meta from './mixins/Meta'
import ProjectAbout from './components/landing/ProjectAbout'
import SectionNews from './components/SectionNews'
import SectionProjects from './components/SectionProjects'
import { getters } from './utils'
import why from './components/landing/why.json'

export default {
  mixins: [
    Meta(
      {
        inner: 'Saulės elektros energija',
        separator: '|',
        complement: 'Saulės bendruomenė',
      },
      undefined,
      require('@/assets/social/homepage.jpg'),
    ),
  ],
  components: {
    CardFlip,
    SectionProjects,
    SectionNews,
    // Calculator,
    FooterLanding,
    Hero,
    HowItWorks,
    IconColumns,
    ProjectAbout,
  },
  data() {
    return {
      amount: 200,
      why,
    }
  },
  computed: getters('isDesktop', 'mainProject'),
  methods: {
    trackView(isVisible, isRetry) {
      if (!isVisible) return

      try {
        this.$analytics.fbq.event('ViewContent')
      } catch (error) {
        if (isRetry) return

        setTimeout(() => {
          this.trackView(isVisible, true)
        }, 2000)
      }
    },
  },
}
</script>

<style lang="scss">
#Index {
  background: $grey-lightest;

  .Calculator {
    contain: content;
  }

  .HowItWorks {
    contain: content;
  }
}

</style>
