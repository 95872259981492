<template>
  <div class="KeyValues no-gutters">
    <div v-for="(value, key, index) in data" :key="key" class="key-value">
      <div class="key" :class="{ last: index === lastIndex }">
        {{ key }}
      </div>
      <div class="value" v-html="value" />
    </div>
  </div>
</template>

<script>
import { getters } from '../../utils'

export default {
  props: {
    data: Object,
  },
  computed: {
    lastIndex() {
      return Object.keys(this.data).length - 1
    },
    ...getters('isDesktop'),
  },
}
</script>

<style lang="scss">
.KeyValues {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  text-align: center;
  @include sm {
    flex-direction: row;
  }
  @include md {
    flex-direction: column;
    margin-top: 0;
  }

  .key-value {
    margin: 2rem 0;
    @include sm {
      min-width: 50%;
    }
    @include md {
      margin: 0;
    }

    .key {
      font-size: $h4;
      font-weight: $light;
      line-height: 2rem;

      &.last {
        border: none;
      }
    }

    .value {
      font-size: $h4;
      font-weight: $semibold;
      line-height: $lh;
      @include md {
        font-size: $h3;
      }
    }
  }
}
</style>
