<template>
  <div class="HowToOrder">
    <h2>Kaip užsakyti dalį nutolusios saulės elektrinės?</h2>
    <IconColumns :classes="classes" :data="steps" />
  </div>
</template>

<script>
import IconColumns from './IconColumns'
import steps from './steps.json'

export default {
  components: { IconColumns },
  props: {
    classes: {
      default: 'single-line',
      type: String,
    },
  },
  data() {
    return {
      steps,
    }
  },
}
</script>

<style lang="scss">
.HowToOrder {
  margin-top: 2.5rem;
  @include lg {
    margin-top: 7rem;
  }

  h2 {
    color: $main;
    margin-bottom: 1rem;
    text-align: center;
    @include lg {
      margin-bottom: 4rem;
    }
  }
}
</style>
