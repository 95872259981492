<template>
  <div class="ProjectHome">
    <h3>{{ project.name }}</h3>
    <Row>
      <Column :md="6">
        <PinMap
          :hasAnimation="true"
          :name="project.nameShort"
          :position="[project.posX, project.posY]" />
        <template v-if="isActive">
          <Route class="Btn danger lg reserve" data-cy="reserve" :to="routeReserve">
            Rezervuoti!
          </Route>
        </template>
      </Column>
      <Column class="valuesContainer" :md="6">
        <KeyValues v-if="isActive" :data="keyValues" />
        <div v-else class="status-label">
          {{ statusLabel }}
        </div>
      </Column>
    </Row>
    <CapacitySlider
      v-if="isActive"
      :total="project.capacity"
      :used="project.capacityUsed" />
  </div>
</template>

<script>
import CapacitySlider from '../CapacitySlider'
import KeyValues from './KeyValues'
import PinMap from '../PinMap'
import { getters } from '../../utils'

export default {
  components: {
    CapacitySlider,
    KeyValues,
    PinMap,
  },
  props: {
    project: Object,
  },
  computed: {
    isActive() {
      return this.project.status === 'ACTIVE'
    },
    keyValues() {
      return {
        'Projekto būsena': this.statusLabel,
        // 'Rezervacijos terminas': `${this.project.termDays} d.`,
        'Pirkimo kaina su PVM': `${this.project.price} €/kW`,
        'Metinė priežiūros kaina su PVM':
          `${this.$options.filters.commaDecimal(this.project.maintenanceAnnual)} €/kW`,
      }
    },
    routeReserve() {
      if (this.isAuth) {
        return {
          name: 'SelfReservationAdd',
          params: { id: this.project.id },
        }
      }

      return {
        name: 'SelfReservationAddGuest',
        params: { id: this.project.id },
      }
    },
    projectStatus() {
      return this.getProjectStatus(this.project.status)
    },
    statusLabel() {
      if (this.project.status === 'PLANNING') {
        return 'Jau greitai'
      }

      return this.projectStatus.label
    },
    ...getters('getProjectStatus'),
  },
}
</script>

<style lang="scss">
.ProjectHome {
  margin-bottom: 6rem;
  @include sm {
    padding: 0 1rem;
  }
  @include xl {
    margin-bottom: 6rem;
    padding: 0 2rem;
  }

  > .Row > .Column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &.valuesContainer {
      @include md {
        padding: 4rem 0;
      }
      @include lg {
        padding: unset;
      }
    }
  }

  h3 {
    font-size: $h25;
    margin-bottom: 2rem;
    margin-left: 1rem;
    text-align: center;
  }

  .Btn.reserve {
    margin-top: 1rem;
    padding-left: 0;
    padding-right: 0;
  }

  .status-label {
    display: flex;
    flex-direction: column;
    font-size: $h2;
    height: 100%;
    justify-content: center;
    text-align: center;
  }

  .PinMap {
    .name {
      margin-left: 1rem;
      position: static;
      text-align: center;
      width: 100%;
    }
  }

  .CapacitySlider {
    margin: 1rem 1rem 0;
    position: relative;
    z-index: 2;
    @include md {
      margin: 2rem 0 0;
    }

    .used {
      font-size: $h3;
    }
  }
}
</style>
